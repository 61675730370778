(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
const getAttr = (element, attr) => {
  const val = element.attributes[attr];
  if (val) {
    return val.value
  } else {
    return '';
  }
}

const addClass = (element, className) => {
  const existing = getAttr(element, 'class')
    .split(' ')
    .find(className);
  if (!existing) {
    element.attributes.class.value = existing.push(className).join(' ');
  }
  return element;
}

const removeClass = (element, className) => {
  element.attributes.class.value = getAttr(element, 'class')
    .split(' ')
    .filter(c => {
      console.log(c, className);
      return c !== className;
    })
    .join(' ');
  return element;
}

const find = (selector) => {
  return document.querySelector(selector);
}

const findAll = (selector) => {
  return document.querySelector(selector);
}

module.exports = {
  getAttr,
  addClass,
  removeClass,
  find,
  findAll
}

},{}],2:[function(require,module,exports){
var intro = require('./intro');

// Play intro animations
setTimeout(function () {
  intro.start();
}, 500);

},{"./intro":3}],3:[function(require,module,exports){
const { find, removeClass } = require('./dom-utils');

const start = () => {
  const bg = find('#background');
  removeClass(bg, 'intro');
}

module.exports = {
  start: start
}

},{"./dom-utils":1}]},{},[2])